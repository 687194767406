<template>
  <v-container class="container_hello">
    <v-row style="padding: 24px">
      <v-col cols="12">
        <h1 color="secondary">Inverter Work!</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.container_hello {
  max-width: 1200px !important;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
}

</style>
<script>
export default {
  name: "Inverter",

  data: () => ({}),
};
</script>
